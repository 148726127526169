import React, {useEffect, useState} from "react";
import TextArea from "../TypedElements/TextArea/TextArea";
import Header from "../TypedElements/Header/Header";

const AdditionalInformation = function ({handleChange, data}) {

    // let [inputs, setInputs] = useState([{courseName: '', courseEducationalInstitution: '', courseDuration: '', yearOfCourseEnding: ''}, ]);
    // useEffect(()=>{
    //     handleChange('coursesAndTrainings', inputs);
    // },[inputs])
    //
    // const handleInputChange = (index, value, name) => {
    //     let currentName = name.substr(0, name.indexOf('-'))
    //     console.log(inputs)
    //     inputs[index][currentName] = value;
    //     setInputs([...inputs]);
    // };

    return (

        <div className={'computer-skills-container'}>
            <Header header={'ДОПОЛНИТЕЛЬНЫЕ СВЕДЕНИЯ'}></Header>
            <div className={'computer-skills'}>
                <TextArea label = {'Ваши увлечения:'} shouldItBeFilled={false} placeholder={"Увлечения в свободное время"} name={'hobbies'}  value={data.hobbies} handleChange={handleChange} maxLen={512}></TextArea>
                <TextArea label = {'Личные качества:'} shouldItBeFilled={false}  placeholder={'Стрессоустойчивость: "Заключил договор с клиентом, который отказал предыдущим трём менеджерам"'}
                          name={'personalQualities'}  value={data.personalQualities} handleChange={handleChange} maxLen={512}></TextArea>
                <TextArea label = {'Дополнительная информация:'} shouldItBeFilled={false}  placeholder={"Достижения, ключевые знания, навыки и другая значимоя информация для претендуемой должности"}
                          name={'additionalInformation'}  value={data.additionalInformation} handleChange={handleChange} maxLen={512}></TextArea>
            </div>
        </div>
    )
}

export default AdditionalInformation