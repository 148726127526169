import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Header from "../../TypedElements/Header/Header";
import * as styles from "./CoursesAndTrainings.module.css"

const CoursesAndTrainings = function ({handleChange, data, validator, inputs, setInputs}) {
    let [buttonsDescription, changeButtonsDescription] = useState("Добавить ещё")

    // let [inputs, setInputs] = useState([{
    //     courseName: '',
    //     courseEducationalInstitution: '',
    //     courseDuration: '',
    //     yearOfCourseEnding: ''
    // },]);
    useEffect(() => {
        handleChange('coursesAndTrainings', inputs);
    }, [inputs])

    const handleRemoveClick = (index) => {
        if (inputs.length <= 1)
            return;
        if (inputs.length === 2) {
            changeButtonsDescription("Добавить ещё")
        }

        console.log(inputs[index]);
        inputs.splice(index, 1);
        setInputs([...inputs]);
    };

    const handleAddClick = () => {
        inputs.push({courseName: '', courseEducationalInstitution: '', courseDuration: '', yearOfCourseEnding: ''});
        changeButtonsDescription("Удалить / добавить")
        console.log(inputs);
        setInputs([...inputs]);
    };

    const handleInputChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        console.log(inputs)
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };

    const handleYearChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        let x = value.replace(/\D/g, '').match(/(\d{0,4})/);

        value = x[1];
        console.log(value)
        data["coursesAndTrainings"][index].yearOfEnding = value
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };

    //
    // const InputData = ({index, value}) => {
    //     return (
    //         <div>
    //             <Input label={"Назвагние курса:"} placeholder={"Кадровый учёт 1C: Зарплата и управление персоналом 3.1"}
    //                    name={`courseName-${index}`} handleChange={(name, value) => handleInputChange(index, value, name)} value={value.courseName} maxLen={128}></Input>
    //             <Input label={"Учебное заведение:"} placeholder={"Название учебного заведения"}
    //                    name={`courseEducationalInstitution-${index}`} handleChange={(name, value) => handleInputChange(index, value, name)}
    //                    value={value.courseEducationalInstitution} maxLen={64}></Input>
    //             <Input label={"Продолжительность:"} placeholder={"2 месяца"} name={`courseDuration-${index}`}
    //                    handleChange={(name, value) => handleInputChange(index, value, name)} value={value.duration} maxLen={32}></Input>
    //             <Input label={"Год окончания:"} placeholder={"гггг"} name={`yearOfCourseEnding-${index}`}
    //                    handleChange={(name, value) => handleInputChange(index, value, name)} value={value.yearOfEnding} maxLen={4}></Input>
    //         </div>
    //     )


    console.log(data["coursesAndTrainings"])
    return (
        <div className={"courses-and-trainings-container"}>
            <Header header={"КУРСЫ И ТРЕНИНГИ"}/>
            {inputs && inputs.length ? inputs?.map((value, index) => {
                return (data["coursesAndTrainings"][index] ?<>
                        <div key={index}>

                            <Input validator={[validator[6]]} label={"Название курса:"}
                                   placeholder={"Кадровый учёт 1C: Зарплата и управление персоналом 3.1"}
                                   name={`courseName-${index}`}
                                   handleChange={(name, value) => handleInputChange(index, value, name)}
                                   value={data["coursesAndTrainings"][index].courseName} maxLen={128}/>
                            <Input validator={[validator[6]]} label={"Учебное заведение:"}
                                   placeholder={"Название учебного заведения"}
                                   name={`courseEducationalInstitution-${index}`}
                                   handleChange={(name, value) => handleInputChange(index, value, name)}
                                   value={data["coursesAndTrainings"][index].courseEducationalInstitution} maxLen={64}/>
                            <Input validator={[validator[6]]} label={"Продолжительность:"} placeholder={"2 месяца"}
                                   name={`courseDuration-${index}`}
                                   handleChange={(name, value) => handleInputChange(index, value, name)}
                                   value={data["coursesAndTrainings"][index].courseDuration} maxLen={32}/>
                            <Input validator={[validator[6], validator[4]]} label={"Год окончания:"} placeholder={"гггг"}
                                   name={`yearOfCourseEnding-${index}`}
                                   handleChange={(name, value) => handleYearChange(index, value, name)}
                                   value={data["coursesAndTrainings"][index].yearOfCourseEnding} maxLen={4}/>
                            <div className={styles.buttonsContainer}>
                                <div className={styles.buttonsDescription}>{buttonsDescription}</div>
                                {inputs.length > 1 ?
                                    <button className={styles.button} onClick={() => handleRemoveClick(index)}>
                                        <svg width="16px" height="16px" viewBox="0 0 48 48" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                            <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round"
                                                  stroke-linejoin="round"/>
                                        </svg>
                                    </button> : ''}
                                <button className={styles.button} onClick={handleAddClick}>
                                    <svg width="18px" height="18px" viewBox="0 0 48 48" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                        <path d="M24.0607 10L24.024 38" stroke="black" stroke-width="4"
                                              stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </button>
                            </div>

                        </div>

                </>:'')
            }) : ''}

        </div>
    );
}

export default CoursesAndTrainings