import React, {useContext, useEffect, useState} from "react";
import * as styles from './TextArea.module.css';
import {InvalidInputs} from "../../../context/context";
const TextArea = function ({maxLen, label, placeholder, name, value, handleChange,shouldItBeFilled}) {



    let {fields, setFields} = useContext(InvalidInputs)
    if (!fields.hasOwnProperty(name)){
        fields[name]=true;
        setFields({...fields})
    }
    const onChange = (event)=>{
        if (shouldItBeFilled){
            if (event.target.value.length > 0){
                fields[event.target.name] = true;
            } else {
                fields[event.target.name] =false;
            }
            setFields({...fields})
        }
        handleChange(event.target.name, event.target.value)
    }


    return (
        <div className={styles.container}>
            <div className={styles.label}>{label}</div>
            <div className={styles.textAreaWrapper}>
                <textarea name={name} value={value} className={!fields[name] ? `${styles.inputError}` : `${styles.input}`} placeholder={placeholder} maxLength={maxLen} onChange={onChange}/>
                <div className={styles.lengthStatus}>{value?.length || 0}/{maxLen}</div>
            </div>
        </div>
    );
}

export default TextArea