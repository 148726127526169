import React, {useEffect, useState} from "react";

import TextArea from "../../TypedElements/TextArea/TextArea";
import Header from "../../TypedElements/Header/Header";
import Scale from "../../TypedElements/Scale/Scale";
import * as styles from './ComputerSkills.module.css';
const ComputerSkills = function ({handleChange, data, validator, fields, setFields}) {
    let [inputs, setInputs] = useState([{programsAndSkills: '', programmingSkillsRating: 5}, ]);

    useEffect(()=>{
        // handleChange('programsAndSkills', inputs[0].programsAndSkills);
        handleChange('programmingSkillsRating', inputs[0].programmingSkillsRating);
    },[inputs])

    const handleProgramsAndSkillsChange = (value) => {
        inputs[0].programsAndSkills = value;
        setInputs([...inputs]);
    };

    const handleRatingChange = (value) => {
        inputs[0].programmingSkillsRating = value;
        setInputs([...inputs]);
    };



    return (

        <div className={'computer-skills-container'}>
            <Header header={'ВЛАДЕНИЕ КОМПЬЮТЕРОМ'}></Header>
            <div className={styles.container}>
                <TextArea  name={'programsAndSkills'} value={data.programsAndSkills} label = {'Программы и навыки:'} placeholder={"Microsoft Word, Excel, Figma"}
                          maxLen={512} handleChange={handleChange} shouldItBeFilled={true}></TextArea>

                <Scale name={"programmingSkillsRating"}   handleChange={(name, value) => handleRatingChange( value)} ></Scale>
            </div>
        </div>
    )
}

export default ComputerSkills