import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Header from "../../TypedElements/Header/Header";
import Scale from "../../TypedElements/Scale/Scale";
import {useForm} from "react-hook-form";
import * as styles from './LanguageSkills.module.css';

const LanguageSkills = function ({handleChange, data, validator, inputs, setInputs}) {
    // let [inputs, setInputs] = useState([{languageName: '', rating: 5},]);
    useEffect(() => {
        handleChange('languages', inputs);
    }, [inputs])


    const handleRemoveClick = (index) => {
        if (inputs.length <= 1)
            return;
        console.log(inputs[index]);
        inputs.splice(index, 1);
        setInputs([...inputs]);
    };

    const handleAddClick = () => {
        inputs.push({languageName: '', rating: 5});
        // inputs.push({languageName: '', rating: 5});
        console.log(inputs);
        setInputs([...inputs]);
    };

    const handleLanguageChange = (index, value) => {
        inputs[index].languageName = value;
        setInputs([...inputs]);
    };

    const handleRatingChange = (index, value) => {
        inputs[index].rating = value;
        setInputs([...inputs]);
    };


    return (
        <div className={'language-skills-container'}>
            <Header header={"ВЛАДЕНИЕ ЯЗЫКАМИ"}></Header>
            {inputs && inputs.length ? inputs?.map((value, index) => {
                return(data["languages"][index]?
                    <div className={styles.languageSkills} key={index}>
                    <Input validator={[validator[7]]} label={'Язык:'} placeholder={"Английский"} name={`languageName-${index}`}
                           handleChange={(name, value) => handleLanguageChange(index, value)} value={data["languages"][index].languageName}
                           maxLen={16}></Input>

                    <div className={styles.buttonsContainer}>

                        {inputs.length > 1 ? <button className={styles.button} onClick={() => handleRemoveClick(index)}><svg width="16px" height="16px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                            <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></button> : ''}
                        {index === inputs.length - 1 ? <button className={styles.button}  onClick={handleAddClick}><svg width="18px" height="18px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                            <path d="M24.0607 10L24.024 38" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></button> : ''}
                    </div>
                    <Scale name={`languageSkillRating-${index}`}
                           handleChange={(name, value) => handleRatingChange(index, value)}></Scale>
                </div>:'')
            }) : ''}

        </div>
    )
}

export default LanguageSkills
