import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import TextArea from "../../TypedElements/TextArea/TextArea";
import Header from "../../TypedElements/Header/Header";
import Checkbox from "../../TypedElements/Checkbox/Checkbox";
import * as styles from './WorkExperience.module.css';

const WorkExperience = function ({handleChange, data, validator, inputs, setInputs, fields, setFields}) {

    let [buttonsDescription, changeButtonsDescription] = useState("Добавить ещё")

    // let [inputs, setInputs] = useState([{
    //     beginningOfWork: '',
    //     endOfWork: '',
    //     organization: '',
    //     position: '',
    //     responsibilitiesAndAchievements: ''
    // },]);
    useEffect(() => {
        handleChange('workExperience', inputs);
    }, [inputs])

    const handleRemoveClick = (index) => {
        if (inputs.length <= 1)
            return;
        if (inputs.length === 2){
            changeButtonsDescription("Добавить ещё")
        }

        console.log(inputs[index]);
        inputs.splice(index, 1);
        setInputs([...inputs]);
    };

    const handleAddClick = () => {
        inputs.push({
            beginningOfWork: '',
            endOfWork: '',
            endOfWorkStatus: 0,
            organization: '',
            position: '',
            responsibilitiesAndAchievements: ''
        });
        changeButtonsDescription("Удалить / добавить")
        console.log(buttonsDescription)
        console.log(inputs);
        setInputs([...inputs]);
    };

    const handleInputChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        console.log(inputs)
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };
    const [isInputClickable, setInputStatus] = useState(true)
    const [checked, setChecked] = useState(false)
    const clickedCheckbox = (data, index) => {
        let checkBoxCheckedStatus = data["workExperience"][index]["endOfWorkStatus"];
        let fieldName = 'endOfWork-' + index
        console.log( fields[fieldName])
        if (checkBoxCheckedStatus) {
            setInputStatus(true)
            fields[fieldName] = true;
            setFields({...fields})
        } else {
            setInputStatus(false)

        }
        // setInputStatus(!isInputClickable)
        console.log(isInputClickable)

    }

    const handleDateChange = (index, name, value) => {
        let currentName = name.substr(0, name.indexOf('-'))
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,4})/);
        value = !x[2] ? x[1] : x[1] + '.' + x[2];
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };
    const handleYearChange = (index, name, value) => {
        let currentName = name.substr(0, name.indexOf('-'))
        let x = value.replace(/\D/g, '').match(/(\d{0,4})/);
        value = x[1];
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };


    const textAreaPlaceholder = "Рекомендуем указывать не более 10 обязанностей и минимум 1-2 достижения, с конкретными результатами, и цифрами"

    return (
        <div className={"work-experience-container"}>
            <Header header={"ОПЫТ РАБОТЫ"}></Header>
            {inputs && inputs.length ? inputs?.map((value, index) => {
                return ( data["workExperience"][index] ?
                    <div key={index}><Input validator={[validator[6], validator[4], validator[9]]} label={"Начало работы:"} name={`beginningOfWork-${index}`}
                                                handleChange={(name, value) => {
                                                    handleYearChange(index, name, value)
                                                }} value={data["workExperience"][index].beginningOfWork}
                                                placeholder={"гггг"} maxLen={4}/>

                    <div className={styles.inputAndSelect}>
                        <Input validator={[validator[6], validator[3]]} isInputClickable={isInputClickable} label={"Окончание работы:"}
                               name={`endOfWork-${index}`} value={data["workExperience"][index].endOfWork}
                               handleChange={(name, value) => {
                                   handleDateChange(index, name, value)
                               }} placeholder={"мм.гггг"} maxLen={7}/>
                        <Checkbox description={"настоящее время"} name={`endOfWorkStatus-${index}`}
                                  handleChange={(name, value) => {
                                      handleInputChange(index, value, name);
                                      clickedCheckbox(data, index)
                                  }} value={data["workExperience"][index].endOfWorkStatus}/>
                    </div>

                    <Input validator={[validator[6]]} label={"Организация:"} placeholder={"Название организации"} name={`organization-${index}`}
                           value={data["workExperience"][index].organization}
                           handleChange={(name, value) => handleInputChange(index, value, name)} maxLen={64}/>
                    <Input validator={[validator[6]]} label={"Должность:"} name={`position-${index}`} placeholder={"Бухгалтер"}
                           value={data["workExperience"][index].position}
                           handleChange={(name, value) => handleInputChange(index, value, name)} maxLen={64}/>
                    <TextArea name={`responsibilitiesAndAchievements-${index}`} label={"Обязанности и достижения:"}
                              value={data["workExperience"][index].responsibilitiesAndAchievements}
                              placeholder={textAreaPlaceholder} maxLen={1024}
                              handleChange={(name, value) => handleInputChange(index, value, name)} shouldItBeFilled={false}/>
                    <div className={styles.buttonsContainer}>
                        <div className={styles.buttonsDescription}>{buttonsDescription}</div>
                        {inputs.length > 1 ?
                            <button className={styles.button} onClick={() => handleRemoveClick(index)}><svg width="16px" height="16px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg></button> : ''}
                        <button className={styles.button} onClick={handleAddClick}><svg width="18px" height="18px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                            <path d="M24.0607 10L24.024 38" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></button>
                    </div>

                </div> : '')
            }) : ''}
        </div>
    );
}

export default WorkExperience;