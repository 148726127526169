export const listOfRequiredInputs = {
    "dateOfBirth": true,
    "driverLicence": true,
    "email": true,
    "expectedSalary": true,
    "name": true,
    "patronymic": true,
    "personalQualities": true,
    "placeOfResidence": true,
    "workPosition": true,
    "surname": true,
    "phoneNumber": true,
    "socialProfile": true,
    "courseDuration": true,
    "courseEducationalInstitution": true,
    "courseName": true,
    "yearOfCourseEnding": true,
    "educationalInstitution": true,
    "qualification": true,
    "speciality": true,
    "yearOfEndingEducationalInstitution": true,
    "languageName": true,
    "beginningOfWork": true,
    "endOfWork": true,
    "organization": true,
    "position": true,
}

