export const translatedFields = {
    name: "Имя",
    surname: "Фамилия",
    patronymic: "Отчество",
    position: "Должность",
    expectedSalary: "Ожидаемая зарплата",
    busyness: "Занятость",
    workingSchedule: "График работы",
    programsAndSkills: "Программы и навыки",
    driverLicence: "Водительское",
    workPosition: "Должность",
    programmingSkillsRating: "",
        phoneNumber: 'Телефон',
        email: 'Email',
        socialProfile: 'Соц. профиль',
    placeOfResidence: "Место проживания",
    dateOfBirth: "Дата рождения",
    sex:"Пол",
        courseDuration: "Продолжительность курса",
        courseEducationalInstitution: "Учебное заведение",
        courseName: "Название курса",
        yearOfCourseEnding: "Год окончания курса",

        beginningOfWork: "Начало работы",
        endOfWork: "Окончание работы",
        organization: "Организация",
        responsibilitiesAndAchievements: "Обязанности и достижения",

        educationalInstitution: "Учебное заведение",
        degree: "Уровень образования",
        qualification: "Квалификация",
        speciality: "Специальность",
        yearOfEndingEducationalInstitution: "Год окончания учебного заведения",
        languageName: "Язык",
    hobbies:"Ваши увлечения",
    personalQualities:"Личные качества",
    additionalInformation:"Дополнительная информация"
}