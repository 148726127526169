import React, {useState} from "react";
import * as styles from './Select.module.css';

const Select = function ({options, handleChange, label, name, value}) {

    const onChange = (event)=>{
        handleChange(event.target.name, event.target.value)
    }

    return (
        <div className={styles.selectContainer}>
            <div className={styles.label}>{label}</div>
            <div className={styles.rightBlock}></div>
            <select className={styles.select} onChange={onChange} name={name} value={value}>{
                options.map((x, y) =>
                    <option key={y}>{x}</option>
                )}
            </select>
        </div>
    );
}

export default Select;