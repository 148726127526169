import React, {useCallback, useEffect, useState} from "react";
import * as styles from './Checkbox.module.css';

const CheckboxAndLabel = function ({description, label, handleChange, name, value}) {

    const [checked, setChecked] = useState(0)
    const clickedCheckbox = useCallback((event) => {
        // let checkBoxCheckedStatus = event.target.value;
        //  console.log(checkBoxCheckedStatus)
        if (checked === 0){
            setChecked(1)
        } else {
            setChecked(0)
        }
    })


    useEffect(()=>{
        console.log(checked)
        handleChange(name,checked)
    }, [ handleChange, name, checked])
        return (
            <div className={styles.container}>
                <div className={styles.label}>{label}</div>
                <input  name={name} value={value}  checked={value} type={"checkbox"} className={styles.checkbox}>
                </input>
                <label onClick={clickedCheckbox}  name={name} value={value}  htmlFor="checkbox">{description}</label>
                {/*<div className={styles.description}>{description}</div>*/}
            </div>
        );

}

export default CheckboxAndLabel;