import {InvalidInputs} from "../context/context";
import {useContext} from "react";
import {listOfRequiredInputs} from "../emptyFields";
import {translatedFields} from "./translatedFields";

export function validateEmail(currentField) {
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    // {
    //     return (true)
    // }
    // alert("You have entered an invalid email address!")
    // return (false)
    if (typeof currentField == 'object') {
        return false;
    } else if (currentField === "" || typeof currentField === 'undefined') {
        return false;
    } else {

        let emailBeforeAt = currentField.substr(0, currentField.indexOf('@'))
        if (emailBeforeAt.length >= 65) {
            return false
        }
        return String(currentField)
            .toLowerCase()
            .match(
                /^(([\w-]+(\.[\w-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }


}

export function validatePhoneNumber(phone) {
    let isPhoneValid = /^\+375 \([0-9]{2}\) [0-9]{3}\-[0-9]{2}\-[0-9]{2}$/.test(phone)
    // console.log(isPhoneValid)

    if (typeof phone == 'object') {

        return false;
    } else if (phone === "" || typeof phone === 'undefined') {
        return false;
    } else {
        return isPhoneValid
    }

}

export function validateDateMonthYear(currentField, currentName, fields, setFields) {
    let month = null;
    let year = null;
    if (currentField.length > 0) {
        month = currentField.substr(0, currentField.indexOf('.'))
        year = currentField.substr(3)
    } else {
        return false
    }

    if (month > 12) {
        fields[currentName] = false;
        setFields({...fields})
        return false
    }
    if (year > 2150 || year < 1950) {
        fields[currentName] = false;
        setFields({...fields})
        return false
    }
    if (currentField.length === 7) {
        return true;
    } else {
        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
}

export function validateDateYear(currentField, currentName, fields, setFields) {
    if (currentField > 2150 || currentField < 1950) {
        fields[currentName] = false;
        setFields({...fields})
        return false
    }
    if (currentField.length === 4) {
        return true;
    } else {

        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
}

export function regexDate(date) {
    return date.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)
}

export function validateDateDayMonthYear(currentField, currentName, fields, setFields) {
    if (!regexDate(currentField)) {
        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
    if (currentField.length === 10) {
        return true;
    } else {
        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
}

export function validateDateLessThanCurrentYear(currentField, currentName, fields, setFields) {
    if (currentField > 2150 || currentField < 1950) {
        fields[currentName] = false;
        setFields({...fields})
        return false
    }
    if (currentField.length === 4) {
        let currentYear = new Date().getFullYear()
        console.log(currentYear)
        console.log(currentField)
        console.log(currentField > currentYear)
        if (currentField > currentYear) {
            fields[currentName] = false;
            setFields({...fields})
            return false;
        } else return true;
    } else {
        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
}

export function validateSocialProfile(currentField, currentName, fields, setFields) {

    if (currentField.length >= 6) {
        return true;
    } else if (currentField.length == 0) {
        return true;
    }
    {
        fields[currentName] = false;
        setFields({...fields})
        return false;
    }
}

export function returnTrue() {
    return true;
}

export function checkIfFieldsAreFilled(data, mainDataFields, fields, setFields, counter) {
    console.log(data)
    for (let i = 0; i <= Object.keys(data).length; i++) {
        for (let key in data) {
            let currentField = data[key]
            if (key === mainDataFields[i]) {
                if (typeof currentField == 'object') {
                    // console.log(fields)
                    // console.log(key)
                    if (counter !== undefined && counter !== null) {
                        let fieldName = key + "-" + counter;
                        fields[fieldName] = false;
                    } else {
                        fields[key] = false;
                    }
                    setFields({...fields})
                    return false;
                } else if (currentField === "" || typeof currentField === 'undefined') {
                    alert(`Незаполнено поле: ${translatedFields[key]} `)
                    // console.log(fields)
                    // console.log(key)
                    if (counter !== undefined && counter !== null) {
                        let fieldName = key + "-" + counter;
                        // console.log(fieldName)
                        fields[fieldName] = false;
                    } else {
                        fields[key] = false;
                    }
                    setFields({...fields})
                    return false;
                } else {
                    // console.log('yay')
                }
            }

        }
    }
    return true;
}

// checkIfFieldsAreFilled(data, mainDataFields)
// console.log(Object.keys(data).length)
// console.log(data)
// if (data["mainData"][mainDataFields[i]]!=""){
//     alert("mainDataError")
//     return false;
// }


export function validateMainData(data, formData, fields, setFields) {
    console.log("main data")
    const necessaryFields = ["surname", "name", "patronymic", "workPosition", "expectedSalary"]
    return checkIfFieldsAreFilled(data, necessaryFields, fields, setFields);
}

export function validateContactDetails(data, formData, fields, setFields) {
    // console.log(fields)
    console.log("contact details")
    for (let i = 0; i < Object.keys(data["contactDetails"]).length; i++) {
        let preparedData = data["contactDetails"][i];
        console.log(preparedData)

        const necessaryFields = ["socialProfile"]
        // console.log(validateSocialProfile(data['socialProfile'], "socialProfile", fields, setFields))
        if (!validatePhoneNumber(preparedData["phoneNumber"])) {
            alert('Неправильно набран номер телефона')
            // console.log(fields)
            let currentName = "phoneNumber-" + i;
            fields[currentName] = false;
            setFields({...fields})
            return false;
        }
        console.log(data['email'])
        if (typeof data['email'] !== 'undefined') {
            if (data['email'].length !== 0) {
                if (!validateEmail(data["email"])) {
                    // console.log(preparedData["email"])
                    alert('Неправильно введён email')
                    let currentName = "email";
                    fields[currentName] = false;
                    setFields({...fields})
                    return false;
                }
            } else if (data['email'].length === 0){
                delete data['email']
            }
        }

        if (typeof data['socialProfile'] !== 'undefined') {
            if (data['socialProfile'].length > 0) {
                if (data['socialProfile'].length < 6) {
                    alert("Слишком короткий соц. профиль")
                    let currentName = "socialProfile";
                    fields[currentName] = false;
                    setFields({...fields})
                    return false
                }
            } else  if (data['socialProfile'].length === 0){
                delete data['socialProfile']
            }
        }


    }
    return true;
}

export function validatePersonalData(data, formData, fields, setFields) {
    console.log("personalData")
    const necessaryFields = ["placeOfResidence", "dateOfBirth"]
    let dateOfBirth = data.dateOfBirth
    if (!checkIfFieldsAreFilled(data, necessaryFields, fields, setFields)) {
        return false;
    }
    if (!validateDateDayMonthYear(dateOfBirth, "dateOfBirth", fields, setFields)) {
        // console.log(dateOfBirth)
        alert("Неправильно введена дата рождения")
        return false;
    }
    if (typeof data['driverLicence'] !== 'undefined') {
        if (data["driverLicence"].length === 0) {
            delete data["driverLicence"]
            console.log(data)
            console.log('deleted')
        }
    }

    return true;
}


export function validateWorkExperience(data, formData, fields, setFields, handleChange) {
    console.log("work experience")
    for (let q = 0; q < data["workExperience"].length; q++) {
        for (let i = 0; i < Object.keys(data["workExperience"][0]).length; i++) {
            let preparedData = data["workExperience"][q];
            // console.log(Object.keys(data["workExperience"][0]))
            const necessaryFields = ["beginningOfWork", "organization", "position"]
            if (!checkIfFieldsAreFilled(preparedData, necessaryFields, fields, setFields, q)) {
                return false;
            }
            let startOfWork = data['workExperience'][q]["beginningOfWork"]
            let beginningOfWorkFieldName = "beginningOfWork-" + q
            // console.log(startOfWork)
            if (!validateDateLessThanCurrentYear(startOfWork, beginningOfWorkFieldName, fields, setFields)) {
                alert('Неправильно введён год начала работы')
                return false;
            }
            // console.log(data['workExperience'][q]["endOfWorkStatus"])
            let endOfWorkFieldName = "endOfWork-" + q
            console.log(data['workExperience'][q])
            if (data['workExperience'][q]["endOfWorkStatus"] === 0) {
                console.log("checkbox")
                if (!checkIfFieldsAreFilled(preparedData, ["endOfWork"], fields, setFields, q)) {
                    return false;
                } else if (!validateDateMonthYear(data['workExperience'][q]["endOfWork"], endOfWorkFieldName, fields, setFields)) {
                    alert("Неправильно введён год окончания работы")
                    return false
                }
            }
            console.log(data['workExperience'][q])

            if (data['workExperience'][q]["endOfWorkStatus"] === 1) {
                if (typeof data['workExperience'][q]["endOfWork"] !== 'undefined') {
                    delete data['workExperience'][q]["endOfWork"]
                    console.log(data)
                    console.log('deleted')
                }
            }

            console.log(data['workExperience'][q])
            if (typeof data['workExperience'][q]["responsibilitiesAndAchievements"] !== 'undefined') {
            if (data['workExperience'][q]["responsibilitiesAndAchievements"].length === 0) {
                delete data['workExperience'][q]["responsibilitiesAndAchievements"]
                console.log(data)
                console.log('deleted')
            }
        }}
    }
    // if (typeof data["workExperience"] !== 'undefined') {
    //     if (Object.keys(data["workExperience"]).length !== 0) {
    //         for (let q = 0; q < data["workExperience"].length; q++) {
    //             let beginningOfWorkLength = data["workExperience"][q].beginningOfWork.length
    //             let organizationLength = data["workExperience"][q].organization.length
    //             let positionLength = data["workExperience"][q].position.length
    //             let responsibilitiesAndAchievementsLength = data["workExperience"][q].responsibilitiesAndAchievements.length
    //             if (beginningOfWorkLength === 0 && organizationLength === 0 && positionLength === 0 && responsibilitiesAndAchievementsLength === 0) {
    //                 if (Object.keys(data["workExperience"]).length > 1) {
    //                     let newWorkExperience = data['workExperience']
    //                     console.log(newWorkExperience)
    //                     newWorkExperience.splice(q, 1)
    //                     handleChange('workExperience', newWorkExperience)
    //                 } else {
    //                     let newWorkExperience = []
    //                     handleChange('workExperience', newWorkExperience)
    //                 }
    //             }
    //         }
    //     }}
    return true;
}

export function validateEducation(data, formData, fields, setFields) {
    console.log("education")
    for (let q = 0; q < data["education"].length; q++) {
        for (let i = 0; i < Object.keys(data["education"][0]).length; i++) {
            let preparedData = data["education"][q];
            // console.log(preparedData)
            const necessaryFields = ["educationalInstitution", "degree", "yearOfEndingEducationalInstitution"]
            if (!checkIfFieldsAreFilled(preparedData, necessaryFields, fields, setFields, q)) {
                return false;
            }
        }
        let yearOfEndingEducationalInstitution = data['education'][q]["yearOfEndingEducationalInstitution"]
        let yearOfEndingEducationalInstitutionFieldName = "yearOfEndingEducationalInstitution-" + q
        if (!validateDateYear(yearOfEndingEducationalInstitution, yearOfEndingEducationalInstitutionFieldName, fields, setFields)) {
            alert('Неправильно введён год окончания учебного заведения')
            return false;
        }
        if (typeof data['education'][q]["speciality"] !== 'undefined'){
            if (data['education'][q]["speciality"].length === 0) {
                delete data['education'][q]["speciality"]
                console.log(data)
                console.log('deleted')
            }
        }

    }
    return true;
}

export function validateCoursesAndTrainings(data, formData, fields, setFields, handleChange) {
    console.log(data["coursesAndTrainings"])
    if (typeof data["coursesAndTrainings"] !== 'undefined') {
        if (Object.keys(data["coursesAndTrainings"]).length !== 0) {
            for (let q = 0; q < data["coursesAndTrainings"].length; q++) {
                let courseNameLength = data["coursesAndTrainings"][q].courseName.length
                let courseEducationalInstitutionLength = data["coursesAndTrainings"][q].courseEducationalInstitution.length
                let courseDurationLength = data["coursesAndTrainings"][q].courseDuration.length
                let yearOfCourseEndingLength = data["coursesAndTrainings"][q].yearOfCourseEnding.length
                if (courseNameLength !== 0 || courseEducationalInstitutionLength !== 0 || courseDurationLength !== 0 || yearOfCourseEndingLength !== 0) {
                    for (let i = 0; i < Object.keys(data["coursesAndTrainings"][0]).length; i++) {
                        let preparedData = data["coursesAndTrainings"][q];
                        // console.log(preparedData)
                        const necessaryFields = ["courseName", "courseEducationalInstitution", "courseDuration", "yearOfCourseEnding"]
                        if (!checkIfFieldsAreFilled(preparedData, necessaryFields, fields, setFields, q)) {
                            return false;
                        }
                    }
                    let yearOfCourseEnding = data['coursesAndTrainings'][q]["yearOfCourseEnding"]
                    // console.log(data['coursesAndTrainings'][q])
                    let yearOfCourseEndingFieldName = "yearOfCourseEnding-" + q
                    if (!validateDateYear(yearOfCourseEnding, yearOfCourseEndingFieldName, fields, setFields)) {
                        alert('Неправильно введён год окончания учебного заведения')
                        return false;
                    }
                }
            }
        }
    }
    // if (typeof data["coursesAndTrainings"] !== 'undefined') {
    //     if (Object.keys(data["coursesAndTrainings"]).length !== 0) {
    //         for (let q = 0; q < data["coursesAndTrainings"].length; q++) {
    //             let courseNameLength = data["coursesAndTrainings"][q].courseName.length
    //             let courseEducationalInstitutionLength = data["coursesAndTrainings"][q].courseEducationalInstitution.length
    //             let courseDurationLength = data["coursesAndTrainings"][q].courseDuration.length
    //             let yearOfCourseEndingLength = data["coursesAndTrainings"][q].yearOfCourseEnding.length
    //             if (courseNameLength === 0 && courseEducationalInstitutionLength === 0 && courseDurationLength === 0 && yearOfCourseEndingLength === 0) {
    //                 if (Object.keys(data["coursesAndTrainings"]).length > 1) {
    //                     let newCoursesAndTrainings = data['coursesAndTrainings']
    //                     console.log(newCoursesAndTrainings)
    //                     newCoursesAndTrainings.splice(q, 1)
    //                     handleChange('coursesAndTrainings', newCoursesAndTrainings)
    //                 } else {
    //                     let newCoursesAndTrainings = []
    //                     handleChange('coursesAndTrainings', newCoursesAndTrainings)
    //                 }
    //             }
    //         }
    //     }
    // if (typeof data["coursesAndTrainings"] !== 'undefined') {
    //     if (Object.keys(data["coursesAndTrainings"]).length === 0) {
    //         delete data["coursesAndTrainings"];
    //     }
    // }
    // }
    return true;
}

export function validateLanguageSkills(data, formData, fields, setFields, handleChange) {
    console.log('language skills')
    if (typeof data["languages"] !== 'undefined') {
        if (Object.keys(data["languages"]).length !== 0) {
            for (let q = 0; q < data["languages"].length; q++) {
                let courseNameLength = data["languages"][q].languageName.length
                if (courseNameLength !== 0) {
                    for (let i = 0; i < Object.keys(data["languages"][0]).length; i++) {
                        let preparedData = data["languages"][q];
                        console.log(preparedData)
                        // console.log(preparedData)
                        if (!checkIfFieldsAreFilled(preparedData, ["languageName"], fields, setFields, q)) {
                            return false;
                        }
                    }
                }

            }
        }
    }

    // if (typeof data["languages"] !== 'undefined') {
    //     if (Object.keys(data["languages"]).length !== 0) {
    //         for (let q = 0; q < data["languages"].length; q++) {
    //             let courseNameLength = data["languages"][q].languageName.length
    //             if (courseNameLength === 0) {
    //                 if (Object.keys(data["languages"]).length > 1) {
    //                     let newLanguages = data['languages']
    //                     console.log(newLanguages)
    //                     newLanguages.splice(q, 1)
    //                     handleChange('workExperience', newLanguages)
    //                 } else {
    //                     let newWorkExperience = []
    //                     handleChange('workExperience', newWorkExperience)
    //                 }
    //             }
    //         }
    //     }}
    // if (typeof data["languages"] !== 'undefined') {
    //     if (Object.keys(data["languages"]).length !== 0) {
    //         for (let q = 0; q < data["languages"].length; q++) {
    //             let courseNameLength = data["languages"][q].languageName.length
    //             console.log(courseNameLength)
    //             if (courseNameLength === 0) {
    //                 delete data["languages"][q]
    //             }
    //         }
    //     }
    // }
    // if (typeof data["languages"] !== 'undefined') {
    //     if (Object.keys(data["languages"]).length === 0) {
    //         delete data["languages"];
    //     }
    // }
    return true;
}

export function validateComputerSkills(data, formData, fields, setFields) {
    console.log(data)

    const necessaryFields = ["programsAndSkills", "programmingSkillsRating"]
    if (!checkIfFieldsAreFilled(data, necessaryFields, fields, setFields)) {
        return false;
    } else {
        return true;
    }
}

export function validateAdditionalInformation(data, formData, fields, setFields) {
    const necessaryFields = ["hobbies", "personalQualities", "additionalInformation"]
    if (typeof data['hobbies'] !== 'undefined') {
        if (data['hobbies'].length === 0) {
            delete data['hobbies']
        }
    }
    if (typeof data['personalQualities'] !== 'undefined') {
        if (data['personalQualities'].length === 0) {
            delete data['personalQualities']
        }
    }
    if (typeof data['additionalInformation'] !== 'undefined') {
        if (data['additionalInformation'].length === 0) {
            delete data['additionalInformation']
        }
    }


    return true;
}

export function validateAllFields(data, formData, fields, setFields, handleChange) {
    let validators = [validateMainData, validateContactDetails, validatePersonalData, validateWorkExperience, validateEducation,
        validateCoursesAndTrainings, validateLanguageSkills, validateComputerSkills, validateAdditionalInformation]
    // let validators = [validateMainData]
    console.log(data)
    for (let i = 0; i < validators.length; i++) {
        if (!validators[i](data, formData, fields, setFields, handleChange)) {
            return false;
        }
    }
}

export function validateAllFieldsAndReturnWrongField(data) {
    let validators = [validatePersonalData, validateWorkExperience, validateEducation, validateLanguageSkills, validateComputerSkills, validateAdditionalInformation]
    for (let i = 0; i < validators.length; i++) {
        if (!validators[i](data)) {
            return;
        }
        // if (validators[i]){
        //     console.log(validators[i])арььррьт
        //     return рраа
        // }
    }
}