import React, {useCallback, useContext, useEffect, useState} from "react";
import * as styles from './Input.module.css';
import {InvalidInputs} from "../../../context/context";
import {validateEmail} from "../../../helpers/validators";

const   Input = function ({label, placeholder, name, value, handleChange, maxLen, isInputClickable, minLength, validator}) {
    let {fields, setFields} = useContext(InvalidInputs)
    if (!fields.hasOwnProperty(name)){
        fields[name]=true;
        setFields({...fields})
    }
    const onChange = (event)=>{
        // console.log(validator)
        for (let i =0;i<validator.length;i++){
            fields[event.target.name] = validator[i](event.target.value, event.target.name, fields, setFields);
        }

        setFields({...fields})
        handleChange(event.target.name, event.target.value)
    }

    const onClick = (event)=>{
        console.log(event.target.selectionStart)

    }
    // let submitButton = document.getElementById('submitButton')
    //
    // if (submitButton){
    //     submitButton.addEventListener('click', ()=>{
    //         console.log("submit button clicked")
    //     })
    // }

    // useEffect(()=> {
    //     // console.log(validator)
    //     if (fields)
    //     for(let i=0;i<=fields.length;i++){
    //         let currentName = name.substr(0, name.indexOf('-'))
    //         console.log(currentName)
    //         console.log(validator(value))
    //         if(!validator(value)){
    //             console.log("validation works")
    //             console.log(validator(value))
    //         }
    //
    //         if (fields[i] === currentName){
    //             if (value >= (minLength || 0)){
    //                 console.log(value)
    //                 setFields((prev) => prev.filter(item => item !== currentName))
    //             }
    //         }
    //     }
    //
    // }, [fields, name, value, minLength, setFields])

    return (
        <div className={styles.inputContainer}>
            <div className={styles.label}>{label}</div>
        <div className={styles.rightBlock}>
                <input  disabled={isInputClickable} name={name} value={value} className={!fields[name] ? `${styles.inputError}` : `${styles.input}`} placeholder={placeholder} onClick={(event) =>{onClick(event)}} maxLength={maxLen} onChange={onChange}/>
            </div>
        </div>
    );
}

export default Input