import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Header from "../../TypedElements/Header/Header";
import * as styles from "./ContactDetails.module.css"

const ContactDetails = function ({handleChange, data, validator, inputs, setInputs}) {
    // let [inputs, setInputs] = useState([{phoneNumber: '+375'}, ]);
    useEffect(()=>{
        handleChange('contactDetails', inputs);
    },[inputs])

    const handleRemoveClick = (index) => {
        if (inputs.length <= 1)
            return;
        console.log(inputs[index]);
        inputs.splice(index, 1);
        setInputs([...inputs]);
    };

    const handleAddClick = () => {
        inputs.push({phoneNumber: '+375'});
        console.log(inputs);
        setInputs([...inputs]);
    };

    const handleInputChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        if (value.length===0){
            data["contactDetails"][index].phoneNumber = "+375" + value;
            inputs[index][currentName] = "+375" + value;
        }
        let x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
        value = !x[2] ? x[1] :'+' + x[1] + (!x[3] ? x[2] : ' (' + x[2] + ') ') + x[3] + (x[4] ? '-' + x[4] : '')+ (x[5] ? '-' + x[5] : '');

        if (value.length<=4){
            data["contactDetails"][index].phoneNumber = "+375" ;
            inputs[index][currentName] = "+375" ;
        } else {
            data["contactDetails"][index].phoneNumber = value
            inputs[index][currentName] = value;
        }

        setInputs([...inputs]);
    };

    return (
        <div className={"contact-details-container"}>
            <Header header={"Контакты"}></Header>
            <div className={"contact-details"}>
                {inputs && inputs.length ? inputs?.map((value, index) => {
                    return(
                        data["contactDetails"][index] ?
                        <div className={styles.container} key={index}>
                        <div className={styles.phoneAndButtons}>  <Input validator={[validator[6], validator[2]]} label = {"Телефон:"}  placeholder = {"+375 (44) 123-45-67"} name={`phoneNumber-${index}`}
                                      value={data["contactDetails"][index].phoneNumber} handleChange={(name, value) => {
                            handleInputChange(index, value, name);
                        }} maxLen={19}></Input>
                            <div className={styles.buttonsContainer}>
                                {inputs.length > 1 ?  <button className={styles.button} onClick={() => handleRemoveClick(index)}><svg width="16px" height="16px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                    <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg></button> : ''}
                                {index === inputs.length - 1 ? <button className={styles.button} onClick={handleAddClick}><svg width="18px" height="18px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                    <path d="M24.0607 10L24.024 38" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg></button> : ''}
                            </div>
                        </div>

                    </div> :
                    '')}):''}

                <Input validator={[validator[1]]} label = {"Email:"}  placeholder = {"your_email@gmail.com"}  name={'email'} value={data.email} handleChange={handleChange} maxLen={512}></Input>
                <Input validator={[validator[8]]} label = {"Соц. профиль:"} placeholder = {"https://vk.com/id0000000"}  name={'socialProfile'} value={data.socialProfile} handleChange={handleChange} maxLen={2048}></Input>
            </div>
        </div>
    );
}

export default ContactDetails;