import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {InvalidInputs} from "./context/context";
import {listOfRequiredInputs} from "./emptyFields";
function Main(){

    const [fields, setFields] = useState(listOfRequiredInputs)
    return (
        <React.StrictMode>
            <InvalidInputs.Provider value={{fields, setFields}}>
                <App></App>
            </InvalidInputs.Provider>
        </React.StrictMode>

    )
}

ReactDOM.render(
  <Main></Main>,
  document.getElementById('root')
);

