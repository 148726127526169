import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Select from "../../TypedElements/Select/Select";
import Header from "../../TypedElements/Header/Header";
import * as styles from "./Education.module.css";
import Checkbox from "../../TypedElements/Checkbox/Checkbox";
import TextArea from "../../TypedElements/TextArea/TextArea";


const Education = function ({handleChange, data, validator, inputs, setInputs}) {
    let [buttonsDescription, changeButtonsDescription] = useState("Добавить ещё")

    // let [inputs, setInputs] = useState([{educationalInstitution: '', degree: 'Доктор наук',  speciality: '', qualification: '', yearOfEndingEducationalInstitution: ''}, ]);
    useEffect(()=>{
        handleChange('education', inputs);
    },[inputs])

    const handleRemoveClick = (index) => {
        if (inputs.length <= 1)
            return;
        if (inputs.length === 2){
            changeButtonsDescription("Добавить ещё")
        }
        console.log(inputs[index]);
        inputs.splice(index, 1);
        setInputs([...inputs]);
    };

    const handleAddClick = () => {
        inputs.push({educationalInstitution: '', degree: 'Доктор наук',  speciality: '', yearOfEndingEducationalInstitution: ''});
        changeButtonsDescription("Удалить / добавить")
        console.log(inputs);
        setInputs([...inputs]);
    };

    const handleInputChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        console.log(inputs)
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };

    const handleYearChange = (index, value, name) => {
        let currentName = name.substr(0, name.indexOf('-'))
        let x = value.replace(/\D/g, '').match(/(\d{0,4})/);

        value = x[1];
        console.log(value)
        data["education"][index].yearOfEndingEducationalInstitution = value
        inputs[index][currentName] = value;
        setInputs([...inputs]);
    };



    const selectOptions = ["Доктор наук","Кандидат наук","Высшее","Неоконченное высшее","Среднее специальное","Среднее"]
    return (
        <div className={"education-data-container"}>
            <Header header={"ОБРАЗОВАНИЕ"}></Header>
            {inputs && inputs.length ? inputs?.map((value, index) => {
                return (data["education"][index]?
                    <div key={index}>  <div className={"education-data"}>
                    <Select label={"Уровень:"} options={selectOptions} name={`degree-${index}`} value={data["education"][index].degree}handleChange={(name, value) => handleInputChange(index, value, name)}></Select>
                    <Input validator={[validator[6]]} label = {"Учебное заведение:"} placeholder = {"Название учебного заведения"} name={`educationalInstitution-${index}`}
                           maxLen={128} value={data["education"][index].educationalInstitution} handleChange={(name, value) => handleInputChange(index, value, name)} ></Input>
                    <Input validator={[validator[7]]} label = {"Специальность:"} placeholder = {"Экономика"} name={`speciality-${index}`} value={data["education"][index].speciality} maxLen={128} handleChange={(name, value) => handleInputChange(index, value, name)}></Input>
                    {/*<Input validator={[validator[6]]} label = {"Квалификация:"} placeholder = {"Бакалавр / Магистр / Экономист"} name={`qualification-${index}`} maxLen={64}  value={data["education"][index].qualification} handleChange={(name, value) => handleInputChange(index, value, name)} ></Input>*/}
                    <Input validator={[validator[6], validator[4]]} label = {"Год окончания:"} placeholder = {"гггг"} name={`yearOfEndingEducationalInstitution-${index}`} value={data["education"][index].yearOfEndingEducationalInstitution} maxLen={4} handleChange={(name, value) => handleYearChange(index, value, name)} ></Input>

                    <div className={styles.buttonsContainer}> <div className={styles.buttonsDescription}>{buttonsDescription}</div>
                        {inputs.length > 1 ?
                            <button className={styles.button} onClick={() => handleRemoveClick(index)}><svg width="16px" height="16px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                                <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg></button> : ''}
                        <button className={styles.button} onClick={handleAddClick}><svg width="18px" height="18px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
                            <path d="M24.0607 10L24.024 38" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 24L38 24" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg></button></div>

                </div>
                </div >:'')

            }):''}
        </div>
    );
}

export default Education;