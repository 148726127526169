import React, {useState} from "react";
import * as styles from './Header.module.css';
const Header = function (props) {

    return (
        <div className={styles.headerContainer}>
            <div className={styles.header}>{props.header}</div>
        </div>
    );
}

export default Header