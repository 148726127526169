import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Select from "../../TypedElements/Select/Select";
import Header from "../../TypedElements/Header/Header";
import * as styles from "./PersonalData.module.css"
import InputAndCheckbox from "../../TypedElements/Input/InputAndCheckbox";

const PersonalData = function ({handleChange, data, validator}) {
    //
    let [input, setInput] = useState([{dateOfBirth: ''}, ]);
    useEffect(()=>{
        let inputData = input[0].dateOfBirth;
        console.log(inputData)
        handleChange('dateOfBirth', inputData);
    },[input])
    const sexOptions = ["Не указано","Мужской", "Женский"]

    // const handleDateChange = (value, data) => {
    //     let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
    //     console.log(data.dateOfBirth)
    //     value = !x[2] ? x[1] :  x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '');
    //     handleChange("dateOfBirth",value)
    //
    // };

    const handleInputChange = (name, value, e) => {
        let x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
        console.log(e)
        value = !x[2] ? x[1] :  x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '');
        input[0]["dateOfBirth"] = value;
        setInput([...input]);
    };

    return (
        <div className={"personal-data-container"}>
            <Header header={"ЛИЧНЫЕ СВЕДЕНИЯ"}></Header>
            <div className={"personal-data"}>
                <Input validator={[validator[6]]} label = {"Место проживания:"} placeholder = {"г. Минск"} name={'placeOfResidence'}  handleChange={handleChange} value={data.placeOfResidence} maxLen={95}></Input>
                <Input validator={[validator[6], validator[5]]} label = {"Дата рождения:"} placeholder = {"дд.мм.гггг"} name={'dateOfBirth'}  handleChange={(name,value)=>{handleInputChange(name, value)}} value={data.dateOfBirth} maxLen={10}></Input>
                <div  className={styles.inputAndCheckbox}></div>
                <Input validator={[validator[7]]} label = {"Водительское:"} placeholder = {"Б"} name={'driverLicence'}  handleChange={handleChange} value={data.driverLicence} maxLen={15}></Input>
                <Select label ={"Пол:"} options={sexOptions} name={"sex"} value={data.sex} handleChange={handleChange}></Select>
            </div>
        </div>
    );
}

export default PersonalData;