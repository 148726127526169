import React, {useEffect, useState} from "react";
import * as styles from './Scale.module.css';

// const Scale = function (props) {
//
//     return (
//     );
// }
//
// const Circle = ({marked, circleId}) => {
//     return (
//         <span data-star-id={circleId} className={styles.circle} role="button">
//       {marked ? '\u25CF' : '\u25CB'}
//     </span>
//     );
// };

const emptyCircle = () =>{
    return (
        <div className={styles.emptyCircle}></div>
    )
}

const filledCircle = () =>{
    return (
        <div className={styles.filledCircle}></div>
    )
}
const Circle = ({marked, circleId}) => {
    return (
        <span data-star-id={circleId} className={styles.circle} role="button">
      {marked ?  <div className={styles.filledCircle}>{circleId}</div>:  <div className={styles.emptyCircle}>{circleId}</div>}
    </span>
    );
};


const Scale = ({value, handleChange, name}) => {
    const onChange = (event) => {
        handleChange(name, event.target.getAttribute('data-star-id'))
    }
    const [rating, setRating] = React.useState(parseInt(value) || 5);
    const [selection, setSelection] = React.useState(0);
    useEffect(()=>{
        console.log(rating)
    },[rating])
    const hoverOver = event => {
        let val = 0;
        if (event && event.target && event.target.getAttribute('data-star-id')){
            event.stopPropagation()
            val = event.target.getAttribute('data-star-id');
        }

        setSelection(val);
    };
    return (
        <div className={styles.mainContainer}>
            <div className={styles.info}>Оцените ваш уровень: </div>
        <div className={styles.container}

            onMouseOut={() => hoverOver(null)}

            // onClick={e => setRating(e.target.getAttribute('data-star-id') || rating)}
            onClick={onChange}
            onClick={(e) => {
                e.stopPropagation()
                onChange(e);
                setRating(e.target.getAttribute('data-star-id') || rating);
            }}
            onMouseOver={hoverOver}
        >

            {Array.from({length: 10}, (v, i) => (
                <Circle
                    circleId={i + 1}
                    key={`star_${i + 1}`}
                    marked={selection ? selection >= i + 1 : rating >= i + 1}
                />
            ))}
        </div>
        </div>
    );
};

export default Scale;