import React, {useEffect, useState} from "react";
import Input from "../../TypedElements/Input/Input";
import Select from "../../TypedElements/Select/Select";
import Header from "../../TypedElements/Header/Header";
import * as styles from './MainData.module.css';
import Checkbox from "../../TypedElements/Checkbox/Checkbox";
import CheckboxAndLabel from "../../TypedElements/Checkbox/CheckboxAndLabel";
import blancProfilePicture from "../../../images/blank-profile-picture.png"
const MainData = function ({handleChange, data, formData, setPhoto, validator, image, setImage}) {

    const scheduleOptions = ["Полный день", "Сменный график", "Гибкий график", "Удалённая работа", "Вахтовый метод"]
    const busynessOptions = ["Полная", "Частичная", "Проектная работа", "Стажировка", "Волонтёрство"]
    // const [image, setImage] = useState(blancProfilePicture)

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if (event.target.files[0].size >= 31457280){
                alert("Слишком большой размер фотографии, загрузите фотографию размером не больше 30 мб")
            } else {
                setImage(URL.createObjectURL(event.target.files[0])); // запихнуть в формдату
                setPhoto(event.target.files[0])
            }

        }

    }
    const hiddenFileInput = React.useRef(null);
    const handleClick = event =>{
        hiddenFileInput.current.click();
    }
    let [input, setInput] = useState([{expectedSalary: ''}, ]);
    useEffect(()=>{
        let inputData = input[0].expectedSalary;
        console.log(inputData)
        handleChange('expectedSalary', inputData);
    },[input])
    const handleInputChange = (name, value) => {
        console.log(value)
        let x = value.replace(/\D/g, '').match(/(\d{0,16})/);
        value = x[1];
        input[0]["expectedSalary"] = value;
        setInput([...input]);
    };

    // let [inputs, setInputs] = useState([{courseName: '', courseEducationalInstitution: '', courseDuration: '', yearOfCourseEnding: ''}, ]);
    // useEffect(()=>{
    //     handleChange('mainData', inputs);
    // },[inputs])
    //
    // const handleInputChange = (index, value, name) => {
    //     let currentName = name.substr(0, name.indexOf('-'))
    //     console.log(inputs)
    //     inputs[index][currentName] = value;
    //     setInputs([...inputs]);
    // };

    return (
        <div className={styles.mainDataContainer}>
            <Header header={"ОСНОВНЫЕ ДАННЫЕ"}></Header>
            <div className={styles.mainData}>
                <Input validator={[validator[6]]} label = {"Фамилия:"} placeholder = {"Иванов"} name={'surname'}  handleChange={handleChange} value={data.surname} maxLen={64} ></Input>
                <Input validator={[validator[6]]} label = {"Имя:"} placeholder = {"Иван"} name={'name'}  handleChange={handleChange} value={data.name} maxLen={64} ></Input>
                <Input validator={[validator[6]]} label = {"Отчество:"} placeholder = {"Иванович"} name={'patronymic'}  handleChange={handleChange} value={data.patronymic} maxLen={64} ></Input>
                <Input validator={[validator[6]]} label = {"Должность:"} placeholder = {"Бухгалтер"} name={'workPosition'}  handleChange={handleChange} value={data.workPosition} maxLen={64} ></Input>
                <Input validator={[validator[6]]} label = {"Ожидаемая зарплата (руб):"} placeholder = {"1000"} name={'expectedSalary'}  handleChange={(name,value )=>{handleInputChange(name, value)}} value={data.expectedSalary} maxLen={16} ></Input>
                <Select label = {"График работы:"} options={scheduleOptions} name={'workingSchedule'}  handleChange={handleChange} value={data.workingSchedule} maxLen={32} ></Select>
                <Select label = {"Занятость:"} options={busynessOptions} name={'busyness'}  handleChange={handleChange} value={data.busyness}></Select>
                <CheckboxAndLabel label={"Переезд:"} description={"готов(а)"}  name={"readinessOfRelocation"} value={data.readinessOfRelocation} handleChange={handleChange} />
                <CheckboxAndLabel label={"Командировки:"} description={"готов(а)"}  name={"readinessOfBusinessTrip"} value={data.readinessOfBusinessTrip} handleChange={handleChange} />
            </div>
            <div className={styles.photoContainer}>

                <input ref={hiddenFileInput} type="file" onChange={onImageChange} className={styles.fileInput} />
                <img className={styles.image} src={image}/>
                <button className={styles.loadPhoto} onClick={handleClick}>Загрузить</button>
                <div className={styles.imageInputDescription}>Фото в формате jpg, png, размером не более 30MB</div>

            </div>
        </div>
    );
}

export default MainData;