import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import MainData from "./components/PageBlocks/MainData/MainData";
import ContactDetails from "./components/PageBlocks/ContactDetails/ContactDetails";
import PersonalData from "./components/PageBlocks/PersonalData/PersonalData";
import WorkExperience from "./components/PageBlocks/WorkExpirience/WorkExperience";
import Education from "./components/PageBlocks/Education/Education";
import CoursesAndTrainings from "./components/PageBlocks/CoursesAndTrainings/CoursesAndTrainings";
import LanguageSkills from "./components/PageBlocks/LanguageSkills/LanguageSkills";
import ComputerSkills from "./components/PageBlocks/ComputerSkills/ComputerSkills";
import AdditionalInformation from "./components/PageBlocks/AdditionalInformation";
import "./App.css"
import {InvalidInputs} from "./context/context";
import {
    validateContactDetails,
    validateEmail,
    validateMainData,
    validatePersonalData,
    validatePhoneNumber,
    validateAllFields,
    validateDateMonthYear,
    validateDateYear,
    checkIfFieldsAreFilled,
    validateDateDayMonthYear,
    validateSocialProfile, returnTrue, validateDateLessThanCurrentYear
} from "./helpers/validators";
import {listOfRequiredInputs} from "./emptyFields";
import {translatedFields} from "./helpers/translatedFields";
import blancProfilePicture from "./images/blank-profile-picture.png";

const initialValidator = function (currentField) {
    if (typeof currentField == 'object') {
        return false;
    } else if (currentField === "" || typeof currentField === 'undefined') {
        return false;
    } else {
        return true;
        console.log('yay')
    }
}

function App() {
    let context = useContext(InvalidInputs)
    context.setFields(listOfRequiredInputs);
    const initialData = {
        name: "",
        surname: "",
        patronymic: "",
        workPosition: "",
        expectedSalary: "",
        busyness: "Полная",
        workingSchedule: "Полный день",
        readinessOfRelocation: 0,
        readinessOfBusinessTrip: 0,
        programmingSkillsRating: 5,
        contactDetails: [{
            phoneNumber: '',
            email: '',
            socialProfile: ''
        }],
        placeOfResidence: "",
        dateOfBirth: "",
        driverLicence: "",
        sex: "Не указано",
        coursesAndTrainings: [{
            courseDuration: "",
            courseEducationalInstitution: "",
            courseName: "",
            yearOfCourseEnding: ""
        }],
        workExperience: [{
            beginningOfWork: "",
            endOfWorkStatus: 0,
            endOfWork: "",
            organization: "",
            position: "",
            responsibilitiesAndAchievements: ""
        }],
        education: [{
            educationalInstitution: "",
            degree: "",
            speciality: "",
            yearOfEndingEducationalInstitution: ""
        }],
        languages: [{
            languageName: "",
            rating: "5"
        }],
        hobbies: "",
        personalQualities: "",
        additionalInformation: "",
        socialProfile: "",
        programsAndSkills:"",
        email:""
    };

    let [workExperienceInputs, setWorkExperienceInputs] = useState([{
        beginningOfWork: '',
        endOfWork: '',
        endOfWorkStatus: 0,
        organization: '',
        position: '',
        responsibilitiesAndAchievements: ''
    },]);

    let [educationInputs, setEducationInputs] = useState([{
        educationalInstitution: '',
        degree: 'Среднее',
        speciality: '',
        // qualification: '',
        yearOfEndingEducationalInstitution: ''
    },]);

    let [coursesAndTrainingsInputs, setCoursesAndTrainingsInputs] = useState([{
        courseName: '',
        courseEducationalInstitution: '',
        courseDuration: '',
        yearOfCourseEnding: ''
    },]);

    let [phoneNumberInputs, setPhoneNumberInputs] = useState([{phoneNumber: '+375'}, ]);

    let [languageSkillsInputs, setLanguageSkillsInputs] = useState([{languageName: '', rating: 5},]);
    const [image, setImage] = useState(blancProfilePicture)
    let currentData = null;
    const [data, setData] = useState(initialData);
    const [photo, setPhoto] = useState(null)
    const handleChange = useCallback((key, value) => {
        setData((prevData) => ({...prevData, [key]: value}));
    }, [])
    useEffect(() => {
        currentData = data;
        console.log(currentData)
    }, [data]);

    let [spinnerStatus, setSpinnerStatus] = useState(true)

    let post = function (address, requestBody) {
        setSpinnerStatus(false)
        return fetch(`${address.replace(/^\//g, '')}`, {
            method: 'POST',
            body: requestBody,
        }).then(response => {
            setSpinnerStatus(true)
            //  alert(response)
            return response;
        }).catch();
    }

    let formData = new FormData();
    let clearForm = function () {
        setData(initialData);
        console.log(data)
        formData.delete('personalImage');
        console.log()
        // data["workExperience"] =[{
        //         beginningOfWork: '',
        //         endOfWork: '',
        //         endOfWorkStatus: 0,
        //         organization: '',
        //         position: '',
        //         responsibilitiesAndAchievements: ''
        //     },]
        // // создать ворк експириенс
        setWorkExperienceInputs([{
            beginningOfWork: '',
            endOfWork: '',
            endOfWorkStatus: 0,
            organization: '',
            position: '',
            responsibilitiesAndAchievements: ''
        },])

        setEducationInputs([{
            educationalInstitution: '',
            degree: 'Доктор наук',
            speciality: '',
            yearOfEndingEducationalInstitution: ''
        },]);
        setCoursesAndTrainingsInputs([{
            courseName: '',
            courseEducationalInstitution: '',
            courseDuration: '',
            yearOfCourseEnding: ''
        },]);
        setLanguageSkillsInputs([{languageName: '', rating: 5},]);
        setPhoneNumberInputs([{phoneNumber: '+375'}, ]);

        setImage(blancProfilePicture)
        setPhoto(null)
        for (let key of formData.keys()) {
            formData.delete(key);
        }
        for (let value of formData.values()) {
            console.log(value);
        }
        // for (let i = 0; i <= Object.keys(data).length; i++) {
        //     for (let key in data) {
        //         let currentField = data[key]
        //         if (typeof currentField == 'object') {
        //             let newData = currentField
        //             console.log(newData)
        //             console.log(newData.length)
        //             for (let q = 0; q <= newData.length; q++) {
        //                 for (let childKey in newData[q]){
        //                     setData((prevData) => ({...prevData, [childKey]: ''}))
        //                 }
        //             }
        //         } else if (currentField === "" || typeof currentField === 'undefined') {
        //
        //         } else {
        //             setData((prevData) => ({...prevData, [key]: ''}))
        //         }
        //     }
        // }

        console.log(data)
    }

    let {fields, setFields} = useContext(InvalidInputs)
    const validators = useMemo(() => [checkIfFieldsAreFilled, validateEmail, validatePhoneNumber, validateDateMonthYear, validateDateYear, validateDateDayMonthYear,
        initialValidator, returnTrue, validateSocialProfile, validateDateLessThanCurrentYear], [])

    function checkIfCoursesAndTrainingsShouldBeSent(){
        for (let i=0;i<data["coursesAndTrainings"].length;i++){
            let courseNameLength = data["coursesAndTrainings"][i].courseName.length
            let courseEducationalInstitutionLength = data["coursesAndTrainings"][i].courseEducationalInstitution.length
            let courseDurationLength = data["coursesAndTrainings"][i].courseDuration.length
            let yearOfCourseEndingLength = data["coursesAndTrainings"][i].yearOfCourseEnding.length
            if (courseNameLength !== 0 && courseEducationalInstitutionLength !== 0 && courseDurationLength !== 0 && yearOfCourseEndingLength !== 0) {
                return false
            }
        }
        return true
    }function checkIfLanguagesShouldBeSent(){
        for (let i=0;i<data["languages"].length;i++){
            let courseNameLength = data["languages"][i].languageName.length
            if (courseNameLength !== 0) {
                return false
            }
        }
        return true
    }
    let executeSubmit = function (context) {

        if (photo !== null) {
            formData.set("personalImage", photo)
        }
        console.log(formData)

        console.log(data)
        console.log('validation starts')
        if (validateAllFields(data, formData, fields, setFields, handleChange) !== false) {
            console.log(data)
            for (let key in data) {
                if (typeof data[key] === 'object') {
                    formData.set(key, JSON.stringify(data[key]));
                } else {
                    formData.set(key, data[key]);
                }
            }
            for (let value of formData.values()) {
                console.log(value);
            } console.log(data)
            if(checkIfCoursesAndTrainingsShouldBeSent()){
                formData.delete('coursesAndTrainings')
            }
            if (checkIfLanguagesShouldBeSent()){
                formData.delete('languages')
            }



                // for (let value of formData.values()) {
            //     console.log(value);
            // }
            // clearForm();
            // setVisible(true)
            //
            post('https://cv.renault-belarus.by/server/cv', formData).then((response)=>{
                if (response.ok){
                    clearForm();
                    setVisible(true)
                } else
                    setErrorVisible(true)
            })
            // clearForm();
            // console.log(data)
            //     post('https://cv.renault-belarus.by/server/cv', formData).then((response)=>{
            //         if (response.ok){
            //             clearForm();
            //             setVisible(true)
            //         } else {
            //             if (data['coursesAndTrainings'].length === 0){
            //                 // setData((prevData) => ({...prevData, ['coursesAndTrainings']: [{ courseName: '',
            //                 //         courseEducationalInstitution: '',
            //                 //         courseDuration: '',
            //                 //         yearOfCourseEnding: ''}]}));
            //                 // setCoursesAndTrainingsInputs([{
            //                 //     courseName: '',
            //                 //     courseEducationalInstitution: '',
            //                 //     courseDuration: '',
            //                 //     yearOfCourseEnding: ''
            //                 // },]);
            //             }   if (data['languages'].length === 0){
            //                 setData((prevData) => ({...prevData, ['languages']: [{languageName: '', rating: 5}]}));
            //                 setLanguageSkillsInputs([{languageName: '', rating: 5},]);
            //             }
            //
            //             setErrorVisible(true)
            //         }
            //     }).catch(e => {
            //         console.log(e)
            //     })


        }
    }
    document.title = "Резюме";
    let [isFinalWindowVisible, setVisible] = useState(false)
    let [isErrorWindowVisible, setErrorVisible] = useState(false)
    return (

        <div className={'App'}>

            {/*<Checkbox label={123} options={[1, 'weqe', 43]}/>*/}
            <MainData validator={validators} setPhoto={setPhoto} initialData={initialData} handleChange={handleChange}
                      data={data} image={image} setImage={setImage}/>
            <ContactDetails validator={validators} handleChange={handleChange} data={data} inputs={phoneNumberInputs} setInputs={setPhoneNumberInputs}/>
            <PersonalData validator={validators} handleChange={handleChange} data={data}/>
            <WorkExperience validator={validators} handleChange={handleChange} data={data} inputs={workExperienceInputs}
                            setInputs={setWorkExperienceInputs} fields={fields} setFields={setFields}/>
            <Education validator={validators} handleChange={handleChange} data={data} inputs={educationInputs}
                       setInputs={setEducationInputs}/>
            <CoursesAndTrainings validator={validators} handleChange={handleChange} data={data}
                                 inputs={coursesAndTrainingsInputs} setInputs={setCoursesAndTrainingsInputs}/>
            <LanguageSkills validator={validators} handleChange={handleChange} data={data} inputs={languageSkillsInputs}
                            setInputs={setLanguageSkillsInputs}/>
            <ComputerSkills validator={validators} handleChange={handleChange} data={data} fields={fields} setFields={setFields}/>
            <AdditionalInformation validator={validators} handleChange={handleChange} data={data}/>
            {!spinnerStatus ? <div className={'spinner-wrapper'}>
                <div className={'spinner'}></div>
            </div> : " "}
            <button id={"submitButton"} className={'submitButton'} onClick={(e) => {
                executeSubmit(context);
            }}>Отправить
            </button>

            <div className={isFinalWindowVisible ? 'visibleFinalMessage' : 'notVisibleFinalMessage'}>
                Резюме отправлено!
                <button className={'okayButton'} onClick={(e) => {
                    setVisible(false)
                }}>Ок    </button>
            </div>
            <div className={isErrorWindowVisible ? 'visibleFinalMessage' : 'notVisibleFinalMessage'}>
                Ошибка, поопробуйте снова.
                <button className={'okayButton'} onClick={(e) => {
                    setErrorVisible(false)
                }}>Ок    </button>
            </div>
        </div>

    );
}

export default App;