import React, {useEffect, useState} from "react";
import * as styles from './Checkbox.module.css';

const Checkbox = function ({description, handleChange, name, value}) {

    const [checked, setChecked] = useState(0)
    const clickedCheckbox = (event) => {
        // let checkBoxCheckedStatus = event.target.checked;
        // console.log(checkBoxCheckedStatus)

        if (checked === 0){
            setChecked(1)
        } else {
            setChecked(0)
        }
    }

    useEffect(()=>{
        console.log(checked)
        handleChange(name,checked)
    }, [name, checked])

    return (
            <div className={styles.containerWithoutLabel}>
                <input  name={name} value={value} checked={value} type={"checkbox"} className={styles.checkbox}>
                </input>
                <label onClick={clickedCheckbox}  name={name} value={value} checked={checked}  htmlFor="checkbox">{description}</label>
            </div>
        );
    }



export default Checkbox;